import axios from '../services/service'

const state = {
  users: [],
}

const mutations = {
  GET_USER(statement, users) {
    statement.users = users
  },
}

const actions = {
  getUser({ commit }) {
    const uid = `${localStorage.getItem('uid')}`
    axios
      .get('/user/v1.0/getbyuser/' + uid)
      .then((res) => {
        const users = res.data.data
        commit('GET_USER', users)
      })
      .catch((error) => {
        console.log(error)
      })
  },
}

const getters = {
  users: (statement) => statement.users,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
