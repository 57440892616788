import jwt from 'jsonwebtoken'
import PublicKey from 'raw-loader!./public.txt'

export default {
  asymmetric() {
    let urlParams = new URLSearchParams(window.location.search)
    let uid = urlParams.get('uid') || `${localStorage.getItem('uid')}`

    let data = {
      uid: uid,
      datetime: new Date(),
    }

    let lockkey = Buffer.from(PublicKey).toString('base64')

    try {
      return jwt.sign(data, lockkey, {
        algorithm: 'HS256',
        expiresIn: '4h',
      })
    } catch (e) {
      console.error(e)
    }
  },
}
