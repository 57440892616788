import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database';
import firebaseConfig from './firebaseConfig'

const firebaseApp = firebase.initializeApp(firebaseConfig)

export default {
  rdb:firebaseApp.database(),
  db: firebaseApp.firestore(),
  auth: firebase.auth(),
}