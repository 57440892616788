import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import en from '../lang/en'
import th from '../lang/th'

const messages = {
  en,
  th
}

// Get the saved locale from local storage or use the fallback locale
const savedLocale = localStorage.getItem('locale') || 'th';

const i18n = new VueI18n({
  locale: savedLocale,
  fallbackLocale: 'th',
  messages: messages,
  silentTranslationWarn: true,
})

// Save the selected locale to local storage whenever it changes
i18n._vm.$watch('locale', (newVal) => {
  localStorage.setItem('locale', newVal);
});

export default i18n;
