import axios from 'axios'

let headers = {
  'cache-control': 'no-cache',
}

let accessToken = `${localStorage.getItem('token')}`

if (accessToken && accessToken !== '') {
  headers.Authorization = accessToken
}

const service = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_URL,
  headers: headers,
})

service.interceptors.response.use(
  (response) => {
    if (
      response.data.error.code === 402 ||
      response.data.error.code === 401 ||
      response.data.error.code === 405
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('uid')
      localStorage.removeItem('shopObjectId')
      localStorage.removeItem('loginwith')
      localStorage.removeItem('idToken')

      window.location.href = '/login'
    }

    return response
  },
  (error) => {
    console.log(error.message)
    window.location.href = '/login'
  }
)

export default service
