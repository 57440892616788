<template>
  <div v-if="shops">
    <router-view></router-view>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'

export default {
  components: {
    NoShop,
  },
  computed: {
    ...mapGetters(['shops']),
  },
}
</script>

<style lang="scss">
@import 'assets/scss/style';
@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-straight/css/uicons-regular-straight.css');
</style>
