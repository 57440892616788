import axios from '../services/service'

const state = {
  shops: [],
  subsetsShop: `${localStorage.getItem('shopObjectId')}`,
}

const mutations = {
  GET_SHOP(statement, shops) {
    statement.shops = shops
  },
  SET_SHOP(statement, subset) {
    statement.subsetsShop = subset
  },
}

const actions = {
  getShop({ commit }) {
    const uid = `${localStorage.getItem('uid')}`

    axios
      .get('/shop/v1.0/getbyuser/' + uid)
      .then((res) => {
        const shops = res.data.data
        const shopObjectId = res.data.data[0].objectId
        localStorage.setItem('shopObjectId', shopObjectId)

        if (state.subsetsShop === 'null') {
          state.subsetsShop = shopObjectId
        }
        commit('GET_SHOP', shops)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  setShop(context, subset) {
    context.commit('SET_SHOP', subset)
  },
}

const getters = {
  shops: (statement) => statement.shops,
  shopObjectId: (statement) => statement.subsetsShop,
}

export default {
  state,
  actions,
  mutations,
  getters,
}
