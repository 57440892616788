import axios from '../services/auth'
import router from '../router'
import lineaxios from 'axios'

const state = {
  user: {},
  token: {},
}

const getters = {
  getToken: (statement) => statement.token,
}

const mutations = {
  CREATE_TOKEN(statement, token) {
    statement.token = token
  },
  CUSTOM_TOKEN(statement, token) {
    statement.token = token
  },
}

const actions = {
  logout() {
    localStorage.removeItem('token')
    router.push('/login')
  },
  createToken({ commit }) {
    let idToken = `${localStorage.getItem('idToken')}`

    axios.defaults.headers.common['authorization'] = idToken

    axios.post('/auth/v1.0/generatetoken/user').then((res) => {
      const token = res.data.data.token

      localStorage.setItem('token', token)
      localStorage.removeItem('idToken')
      commit('CREATE_TOKEN', token)

      window.location.href = '/'
    })
  },
  loginWithCustomToken({ commit }) {
    let customToken = `${localStorage.getItem('customToken')}`

    axios.defaults.headers.common['authorization'] = customToken

    axios.post('/auth/v1.0/loginWithCustomToken').then(
      (res) => {
        const token = res.data.data.token

        if (res.data.error.code === 0) {
          localStorage.setItem('token', token)
          localStorage.removeItem('customToken')
          commit('CUSTOM_TOKEN', token)
          window.location.href = '/'
        }
      },
      (err) => {
        console.log(err)
      }
    )
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
